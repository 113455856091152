<script setup lang="ts">
const { id } = defineProps<{
  id: number
}>()

const { data: category, status } = useLazyFetch(`/api/categories/${id}`, {
  key: `category/${id}`,
})
</script>

<template>
  <USkeleton
    v-if="status === 'pending'"
    class="h-6 w-32 dark"
  />
  <NuxtLink
    v-else
    :to="category.link"
    :title="category.name"
  >
    <UBadge>
      {{ category.name }}
    </UBadge>
  </NuxtLink>
</template>
