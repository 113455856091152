<script setup lang="ts">
const { id } = defineProps<{
  id: number
}>()

const { data } = await useFetch(`/api/author/${id}`, {
  key: `author/${id}`,
})
</script>

<template>
  <div
    class="p-4 py-4 bg-gradient-to-r from-gray-100 to-white border shadow-sm border-gray-200"
  >
    <div class="relative">
      <div class="absolute inset-0 flex items-center">
        <div class="w-full border-t border-gray-200" />
      </div>
      <div class="relative flex items-center justify-between">
        <span class="bg-gray-100 pr-3 text-lg font-medium text-gray-800">Autor</span>
      </div>
    </div>

    <div class="flex items-start gap-4 mt-4 mb-4">
      <div class="min-w-[4rem] min-h-[4rem] sm:min-w-[6rem] sm:min-h-[6rem]">
        <NuxtLink :to="{ name: 'author-slug', params: { slug: data.slug } }">
          <NuxtImg
            fit="cover"
            :width="96"
            :height="96"
            :src="data.avatar_urls[96]"
            placeholder
            :quality="95"
            class="rounded border object-cover size-16 sm:size-24 border-gray-300 shadow-xl"
          />
        </NuxtLink>
      </div>
      <div>
        <div class="flex flex-col md:flex-row md:items-center gap-2">
          <NuxtLink
            :to="{ name: 'author-slug', params: { slug: data.slug } }"
            class="text-gray-800 hover:text-primary transition-colors font-semibold"
          >
            {{ data.name }}
          </NuxtLink>
          <NuxtLink to="mailto:redakcja@bitcoin.pl">
            <UBadge label="redakcja@bitcoin.pl" />
          </NuxtLink>
        </div>
        <p class="hidden sm:block text-gray-600 text-sm font-medium mt-1 opacity-95">
          {{ data.description }}
        </p>
      </div>
    </div>
    <p class="block sm:hidden text-gray-600 text-sm font-medium mt-1 opacity-95">
      {{ data.description }}
    </p>
  </div>
</template>
