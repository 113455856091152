<script setup lang="ts">
const { id } = defineProps<{
  id: number
}>()

const { data: tag, status } = useLazyFetch(`/api/tags/${id}`, {
  key: `tag/${id}`,
})
</script>

<template>
  <USkeleton
    v-if="status === 'pending'"
    class="h-6 w-32"
  />
  <NuxtLink
    v-else
    :to="{ name: 'tag-slug', params: { slug: tag.slug } }"
    :title="tag.name"
    data-test-class="articleTag"
  >
    <UBadge>
      <UIcon name="i-heroicons-hashtag" />
      <span class="ml-1">
        {{ tag.name }} ({{ tag.count }})
      </span>
    </UBadge>
  </NuxtLink>
</template>
